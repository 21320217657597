import { Path, useController, UseFormRegister } from "react-hook-form";
import { CheckoutFormValues } from "../pages";

interface Props {
  label: string;
  name: Path<CheckoutFormValues>;
  register: UseFormRegister<CheckoutFormValues>;
  required?: boolean;
  pattern?: RegExp;
  disabled?: boolean;
}

const Input = ({
  label,
  name,
  register,
  pattern,
  required = false,
  disabled = false,
}: Props) => {
  const { fieldState } = useController({ name });

  const error = fieldState.invalid && fieldState.error;
  const borderStyles = error
    ? "border-red-400 focus:border-red-500"
    : "border-gray-200 focus:border-gray-400";

  return (
    <div className="relative">
      <label
        className={`block ${
          error ? "text-red-600" : "text-gray-700"
        } text-sm font-normal mb-1`}
        htmlFor={name}
      >
        {label}
        {required ? "*" : ""}
      </label>
      <input
        id={name}
        disabled={disabled}
        className={`${borderStyles} block appearance-none w-full h-12 bg-white border text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white disabled:bg-gray-50 mb-4`}
        {...register(name, { required, pattern })}
      />
      {error && (
        <span className="absolute -bottom-5 text-xs text-red-600">
          {error.type === "required"
            ? "Detta fält måste fyllas i"
            : (error.type = "pattern" ? "Måste vara en E-postadress" : "")}
        </span>
      )}
    </div>
  );
};

export default Input;
