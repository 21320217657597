import { useQuery, QueryStatus } from "react-query";
import axios from "axios";

interface UseCategories {
  categories: string[];
  status: QueryStatus;
}

export interface CategoriesResponse {
  categories: string[];
}

const useCategories = (): UseCategories => {
  const { data, status } = useQuery<CategoriesResponse>(
    "categories",
    async () => {
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_WEB_BASE}/api/categories`
      );
      return res.data;
    }
  );

  return { categories: data?.categories || [], status };
};

export default useCategories;
