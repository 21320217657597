import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import useVoucher from "../hooks/useVoucher";
import { CheckoutFormValues } from "../pages";
import getUnitFromDiscountType from "../utils/getDiscountTypeFromEnum";
import Button from "./Button";
import Card from "./Card";
import Input from "./Input";

const VoucherSection = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const { register, getValues, setValue } =
    useFormContext<CheckoutFormValues>();
  const { onValidateVoucher } = useVoucher();

  const handleValidation = async () => {
    setIsSubmitting(true);
    const code = getValues("code");
    try {
      const voucher = await onValidateVoucher(code);
      if (voucher) {
        const discountValue = voucher.type === "Percentage" ? voucher.discountValue * 100 : voucher.discountValue;
        setFeedback({
          message: `Du får nu ${discountValue}${getUnitFromDiscountType(voucher.type)} rabatt på din anmälan`,
          type: "success",
        });
        setValue("voucher", {
          ...voucher,
          discountValue: discountValue,
          discountType: voucher.type,
        });
      }
    } catch (err) {
      setFeedback({
        message: `Ingen rabattkod med texten ${code} hittades`,
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="voucher-card mb-4">
      <Card>
        <h2 className="text-xl mb-4">Rabattkod</h2>
        <div className="relative">
          <Input
            name="code"
            label="Kod"
            register={register}
            disabled={feedback?.type === "success"}
          />
          {feedback && (
            <span
              className={`${
                feedback.type === "success" ? "text-green-700" : "text-red-700"
              } text-sm absolute -bottom-6`}
            >
              {feedback.message}
            </span>
          )}
        </div>
        <div className="mt-8">
          <Button
            id="validate-btn"
            variant="secondary"
            type="button"
            onClick={() => handleValidation()}
            disabled={feedback?.type === "success" || isSubmitting}
            showSpinner={isSubmitting}
          >
            Kontrollera rabattkod
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default VoucherSection;
