import {
  useQuery,
  QueryStatus,
  useMutation,
  MutationStatus,
} from "react-query";
import axios from "axios";
import { Runner } from "../interfaces/runner";
import { PaymentMethod } from "../types/paymentMethod";

interface SessionData {
  runners: Runner[];
  totalAmount: number;
  voucherCode?: string;
  phoneNumber?: string;
  paymentMethod: "card" | "swish" | "invoice";
}

export interface Session {
  id: string;
  status: number;
}

export interface SessionResponse extends Session {
  email: string;
  orderNumber: string;
  paymentType: "Card" | "Invoice";
}
export interface CreateSessionResponse {
  session: Session;
  checkoutUrl: string;
}

interface UseSession {
  session?: SessionResponse;
  queryStatus: QueryStatus;
  onCreateSession: (sessionData: SessionData) => Promise<CreateSessionResponse>;
  mutationStatus: MutationStatus;
}

const useSession = (id?: string): UseSession => {
  const { data, status: queryStatus } = useQuery<SessionResponse>(
    ["session", id],
    async () => {
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_WEB_BASE}/api/checkout/session?id=${id}`
      );
      return res.data;
    },
    {
      enabled: Boolean(id),
    }
  );

  const createMutation = useMutation(async (sessionRequest: SessionData) => {
    const { data } = await axios.post<CreateSessionResponse>(
      `${process.env.NEXT_PUBLIC_WEB_BASE}/api/checkout/${sessionRequest.paymentMethod}`,
      sessionRequest
    );
    return data;
  });

  const handleCreateSession = async (sessionData: SessionData) => {
    return await createMutation.mutateAsync(sessionData);
  };

  return {
    session: data,
    queryStatus,
    onCreateSession: handleCreateSession,
    mutationStatus: createMutation.status,
  };
};

export default useSession;
