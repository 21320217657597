import CreatableSelect from "react-select/creatable";
import { useController } from "react-hook-form";
import { selectStyles } from "../styles/selectStyles";
import useTeams from "../hooks/useTeams";

interface Props {
  name: string;
  classNamePrefix: string;
}

const TeamSelect = ({ name, classNamePrefix }: Props) => {
  const { teams, status } = useTeams();
  const { field } = useController({ name });

  return (
    <>
      <label
        className="block text-gray-700 text-sm font-normal mb-2"
        htmlFor={"team-list"}
      >
        Lag{" "}
        <span className="text-xs text-gray-500">
          (Skriv lagnamn eller välj ur lista)
        </span>
      </label>
      <CreatableSelect
        inputId="team-list"
        styles={selectStyles}
        options={teams.map((team) => ({ label: team, value: team }))}
        value={{ value: field.value, label: field.value }}
        onChange={(val) => field.onChange(val?.value)}
        name={field.name}
        isLoading={status === "loading"}
        formatCreateLabel={() => ""}
        onInputChange={(val, { action }) => {
          if (action === "input-change") {
            field.onChange(val);
          }
        }}
        noOptionsMessage={() => null}
        classNamePrefix={classNamePrefix}
      />
    </>
  );
};

export default TeamSelect;
