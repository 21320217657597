import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from "react";
import { ImSpinner9 as SpinnerIcon } from "react-icons/im";

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: "primary" | "secondary" | "danger";
  showSpinner?: boolean;
}

const Button: FC<Props> = ({
  variant,
  type,
  showSpinner = false,
  children,
  ...props
}) => {
  const variantStyles =
    variant === "primary"
      ? "primary bg-thunder hover:bg-thunderLight text-white disabled:bg-thunderLight"
      : variant === "danger"
      ? "danger bg-white hover:bg-red-50 text-red-500 border border-red-200"
      : "secondary bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 disabled:bg-gray-50 disabled:opacity-90 disabled:cursor-not-allowed";

  return (
    <button
      type={type}
      className={`${variantStyles}  py-2 px-4 rounded flex justify-center items-center h-10`}
      {...props}
    >
      {children}
      {showSpinner && (
        <SpinnerIcon
          className={`animate-spin ${
            variant === "primary" ? "text-white" : "text-gray-600"
          } ml-2`}
        />
      )}
    </button>
  );
};

export default Button;
