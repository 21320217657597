interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const HamburgerIcon = ({ isOpen, onToggle }: Props) => {
  return (
    <button
      className="text-white w-6 h-6 z-50 relative focus:outline-none bg-transparent hover:opacity-90 rounded-full"
      onClick={onToggle}
    >
      <span className="sr-only">Öppna meny</span>
      <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span
          data-testid="bar-1"
          aria-hidden="true"
          className={`block absolute h-0.5 w-6 bg-current transform transition duration-300 ease-in-out ${
            isOpen ? "translate-y-0 rotate-45" : "-translate-y-1.5 rotate-0"
          }`}
        ></span>
        <span
          data-testid="bar-2"
          aria-hidden="true"
          className={`block absolute h-0.5 w-6 bg-current transform transition duration-300 ease-in-out ${
            isOpen ? "opacity-0" : "opacity-100"
          }`}
        ></span>
        <span
          data-testid="bar-3"
          aria-hidden="true"
          className={`block absolute h-0.5 w-6 bg-current transform transition duration-300 ease-in-out ${
            isOpen ? "translate-y-0 -rotate-45" : "translate-y-1.5 rotate-0"
          }`}
        ></span>
      </div>
    </button>
  );
};

export default HamburgerIcon;
