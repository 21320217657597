import { useQuery, QueryStatus } from "react-query";
import axios from "axios";

interface UseGroups {
  groups: { value: string; label: string }[];
  status: QueryStatus;
}

export interface GroupsResponse {
  groups: { value: string; label: string }[];
}

const useGroups = (): UseGroups => {
  const { data, status } = useQuery<GroupsResponse>("groups", async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_WEB_BASE}/api/groups`
    );
    return res.data;
  });

  return { groups: data?.groups || [], status };
};

export default useGroups;
