import React, { FC } from "react";

interface Props {
  includePadding?: boolean;
}

const Card: FC<Props> = ({ includePadding = true, children }) => {
  return (
    <div
      className={`${
        includePadding ? "p-4" : ""
      } bg-sky-50 border border-sky-100 rounded relative`}
    >
      {children}
    </div>
  );
};

export default Card;
