export const selectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    boxShadow: "none",
    height: "3rem",
    borderColor: "rgb(229 231 235)",
    marginBottom: "1rem",
    "&:hover": {
      borderColor: isFocused ? "rgb(156 163 175)" : "rgb(229 231 235)",
    },
  }),
  input: (styles) => ({
    ...styles,
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "1rem",
  }),
};

export const errorStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    boxShadow: "none",
    height: "3rem",
    borderColor: "rgb(248, 113, 113)",
    marginBottom: "1rem",
    "&:hover": {
      borderColor: isFocused ? "rgb(239, 68, 68)" : "rgb(248, 113, 113)",
    },
  }),
  input: (styles) => ({
    ...styles,
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "1rem",
  }),
};
