import { useQuery, QueryStatus } from "react-query";
import axios from "axios";

export interface PriceResponse {
  totalAmount: number;
  discountedAmount: number;
  runnerPrice: number;
}

interface UsePrice extends Partial<PriceResponse> {
  status: QueryStatus;
}

const usePrice = (numberOfRunners: number, code?: string): UsePrice => {
  const { data, status } = useQuery<PriceResponse>(
    ["price", numberOfRunners, code],
    async () => {
      const res = await axios.get(
        `${
          process.env.NEXT_PUBLIC_WEB_BASE
        }/api/price?numberOfRunners=${numberOfRunners}${
          code ? `&code=${code}` : ""
        }`
      );
      return res.data;
    },
    {
      enabled: numberOfRunners > 0,
      retry: false,
    }
  );

  return {
    totalAmount: data?.totalAmount,
    discountedAmount: data?.discountedAmount,
    runnerPrice: data?.runnerPrice,
    status,
  };
};

export default usePrice;
