import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import HamburgerIcon from "./HamburgerIcon";
import MobileNavigation from "./MobileNavigation";

export const routes = [
  {
    name: "Tillbaka till goteborgsbrantaste.se",
    path: "https://goteborgsbrantaste.se",
  },
];

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen((o) => !o);

  return (
    <nav className="flex items-center justify-between bg-thunder p-6">
      <div className="absolute hover:cursor-pointer hover:scale-105 hover:opacity-90">
        <a href="https://goteborgsbrantaste.se">
          <Image
            src="/images/logowhite.png"
            height="48"
            width="66"
            alt="Göteborgs brantaste logo"
          />
        </a>
      </div>
      <div className="flex-grow" />
      <div className="flex lg:hidden">
        <HamburgerIcon isOpen={isOpen} onToggle={handleToggle} />
      </div>
      <div className="hidden lg:flex lg:items-center lg:w-auto">
        <div className="text-md lg:flex-grow">
          {routes.map((route) => (
            <a
              href={route.path}
              key={route.path}
              className="block mt-4 mr-4 font-medium uppercase lg:inline-block lg:mt-0 text-white hover:text-gray-200"
            >
              {route.name}
            </a>
          ))}
        </div>
      </div>
      <MobileNavigation isOpen={isOpen} onToggle={handleToggle} />
    </nav>
  );
};

export default Navigation;
