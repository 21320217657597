import Link from "next/link";
import { FC } from "react";
import { routes } from "./Navigation";

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const MobileNavigation: FC<Props> = ({ isOpen, onToggle }) => {
  return (
    <>
      <aside
        data-testid="side-nav"
        className={`transform top-0 right-0 max-w-xs w-full bg-thunder fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="mt-nav" data-testid="route-container">
          {routes.map((route) => (
            <Link href={route.path} key={route.path}>
              <a
                onClick={onToggle}
                className="block p-4 mr-4 font-medium uppercase lg:inline-block lg:mt-0 text-white hover:text-gray-200"
              >
                {route.name}
              </a>
            </Link>
          ))}
        </div>
      </aside>
      {isOpen && (
        <div
          data-testid="overlay"
          onClick={onToggle}
          className="bg-black bg-opacity-20 fixed top-0 left-0 right-0 bottom-0 z-10"
        />
      )}
    </>
  );
};

export default MobileNavigation;
