import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import type { NextPage } from "next";
import Head from "next/head";
import { v4 as uuidv4 } from "uuid";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import RunnerSection from "../components/RunnerSection";
import VoucherSection from "../components/VoucherSection";
import { Runner } from "../interfaces/runner";
import { Voucher } from "../interfaces/voucher";
import Button from "../components/Button";
import PriceSection from "../components/PriceSection";
import PaymentSection from "../components/PaymentSection";
import { PaymentMethod } from "../types/paymentMethod";
import PageLayout from "../components/PageLayout";
import useSession from "../hooks/useSession";
import ErrorToast from "../components/ErrorToast";
import { useRouter } from "next/router";

export interface CheckoutFormValues {
  voucher?: Voucher;
  code?: string;
  runners: Runner[];
  totalAmount: number;
  paymentMethod: PaymentMethod;
  phoneNumber?: string;
}

const defaultValues: CheckoutFormValues = {
  code: "",
  runners: [
    {
      id: uuidv4(),
      firstName: "",
      lastName: "",
      email: "",
      category: "",
      startGroup: "",
      team: "",
      isContact: true,
    },
  ],
  totalAmount: 0,
  paymentMethod: "card",
};

const Home: NextPage = () => {
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState<{
    id: string;
    title: string;
    message: string;
  }>();
  useEffect(() => {
    process.env.NEXT_PUBLIC_ENABLE_TRACKING === "true" &&
      (window as any).fbq("track", "InitiateCheckout");
  }, []);

  const { onCreateSession } = useSession();
  const methods = useForm<CheckoutFormValues>({
    defaultValues
  });
  const isSubmitting = methods.formState.isSubmitting;

  const handleCreateSession: SubmitHandler<CheckoutFormValues> = async (
    data
  ): Promise<void> => {
    return new Promise((resolve) => {
      const requestData = {
        ...data,
        ...(data.voucher && data.code && { voucher: data.code }),
      };
      
      onCreateSession(requestData)
        .then((res) => {
          if (res?.session?.id) {
            window.localStorage.setItem(
              "entry_details",
              JSON.stringify({ ...requestData, sessionId: res.session.id })
            );
            resolve();
            router.push(res.checkoutUrl || `/${res.session.id}`)
          }
        })
        .catch((err) => {
          const message = (err as AxiosError)?.response;
          setErrorMessage({
            id: uuidv4(),
            title: "Ett fel inträffade!",
            message:
              message?.statusText ||
              "Felet har rapporterats. Kontakta support@goteborgsbrantaste.se",
          });
          resolve();
        });
    });
  };

  return (
    <div>
      <Head>
        <title>
          Anmälan | Göteborgs Brantaste {process.env.NEXT_PUBLIC_RACE_YEAR}
        </title>
        <meta
          name="description"
          content="Göteborgs första backlopp i löpning!"
        />
        <link rel="icon" href="/favicon.ico" />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=InitiateCheckout&noscript=1`}
          />
        </noscript>
      </Head>
      <main>
        <PageLayout>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleCreateSession)}
              className="px-4 md:px-8 pt-6 pb-8 mb-4"
            >
              <VoucherSection />
              <RunnerSection />
              <PriceSection />
              <PaymentSection />
              <Button
                type="submit"
                variant="primary"
                id="submit-btn"
                showSpinner={isSubmitting}
                disabled={isSubmitting}
              >
                Till betalning
              </Button>
            </form>
            {errorMessage?.title && errorMessage?.message && (
              <ErrorToast {...errorMessage} duration={60000} />
            )}
          </FormProvider>
        </PageLayout>
      </main>
    </div>
  );
};

export default Home;
