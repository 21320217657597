import { FC } from "react";
import Navigation from "./Navigation";

const PageLayout: FC = ({ children }) => {
  return (
    <>
      <Navigation />
      <main className="max-w-3xl m-auto">{children}</main>
    </>
  );
};

export default PageLayout;
