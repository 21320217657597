import Select from "react-select";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { selectStyles, errorStyles } from "../styles/selectStyles";
import useGroups from "../hooks/useGroups";

interface Props {
  name: string;
  classNamePrefix: string;
}

const GroupSelect = ({ name, classNamePrefix }: Props) => {
  const { groups, status } = useGroups();
  const {} = useFormContext();
  const { field, fieldState } = useController({
    name,
    rules: { required: true },
  });

  const error = fieldState.invalid && fieldState.error;

  const translateLabel = (label: string) => {
    switch (label) {
      case "25+min at 5km":
        return "25+min på 5km";
      case "20-25min at 5km":
        return "20-25min på 5km";
      case "Under 20min at 5km":
        return "Under 20min på 5km";

      default:
        return "";
    }
  };
  const getLabel = (groupItem?: { label: string; value: string }) => {
    if (!groupItem) return "";
    return `Grupp ${groupItem.value} (${translateLabel(groupItem.label)})`;
  };

  return (
    <div className="relative">
      <label
        className={`block ${
          error ? "text-red-600" : "text-gray-700"
        } text-sm font-normal mb-1`}
        htmlFor={"group-list"}
      >
        Startgrupp*
      </label>
      <Select
        inputId="group-list"
        styles={error ? errorStyles : selectStyles}
        options={groups.map((group) => ({
          label: getLabel(group),
          value: group.value,
        }))}
        value={{
          value: field.value,
          label: getLabel(groups.find((g) => g.value === field.value)),
        }}
        onChange={(val) => field.onChange(val?.value)}
        name={field.name}
        isLoading={status === "loading"}
        onBlur={field.onBlur}
        classNamePrefix={classNamePrefix}
      />
      {error && error.type === "required" && (
        <span className="absolute -bottom-5 text-xs text-red-600">
          Detta fält måste fyllas i
        </span>
      )}
    </div>
  );
};

export default GroupSelect;
