import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import usePrice from "../hooks/usePrice";
import { CheckoutFormValues } from "../pages";
import getUnitFromDiscountType from "../utils/getDiscountTypeFromEnum";
import Card from "./Card";

const PriceSection = () => {
  const { control, setValue } = useFormContext<CheckoutFormValues>();
  const runners = useWatch({
    control,
    name: "runners",
  });
  const voucher = useWatch({
    control,
    name: "voucher",
  });
  const numberOfRunners = runners?.length || 0;
  const { totalAmount, discountedAmount, runnerPrice } = usePrice(
    numberOfRunners,
    voucher?.code
  );

  useEffect(() => {
    setValue("totalAmount", totalAmount || 0);
  }, [totalAmount, setValue]);

  const formatValue = (value?: number) => {
    if (!value) return 0;

    return Intl.NumberFormat("sv-SE", {
      style: "currency",
      currency: "SEK",
    }).format(value);
  };

  return (
    <div className="mb-4">
      <Card>
        <h2 className="text-xl mb-4">Pris</h2>
        <div className="flex justify-between grow">
          <span id="runner-count" className="text-sm text-gray-600">
            {numberOfRunners} löpare (á {formatValue(runnerPrice)})
          </span>
          <span id="runner-price" className="text-gray-600">
            {formatValue((runnerPrice || 0) * numberOfRunners)}
          </span>
        </div>
        {Boolean(discountedAmount) && voucher && (
          <div className="flex justify-between grow">
            <span id="discount-description" className="text-sm text-gray-600">
              Rabatt {voucher.discountValue}
              {getUnitFromDiscountType(voucher.discountType)} ({voucher.code})
            </span>
            <span id="discounted-amount" className="text-gray-600">
              - {formatValue(discountedAmount)}
            </span>
          </div>
        )}
        <div className="flex justify-between grow border-t leading-8">
          <span className="font-medium">Totalt pris:</span>
          <span id="total-amount" className="font-medium">
            {formatValue(totalAmount)}
          </span>
        </div>
      </Card>
    </div>
  );
};

export default PriceSection;
