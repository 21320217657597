import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RadioGroup } from "@headlessui/react";
import {
  FaCheckCircle as CheckedIcon,
  FaCircle as UncheckedIcon,
} from "react-icons/fa";
import { CheckoutFormValues } from "../pages";
import { PaymentMethod } from "../types/paymentMethod";
import Card from "./Card";
import Input from "./Input";

const methods: Array<{
  key: PaymentMethod;
  label: string;
  disabled?: boolean;
}> = [
  { key: "card", label: "Kort" },
  {
    key: "swish",
    label: "Swish",
    disabled: process.env.NEXT_PUBLIC_SWISH_DISABLED === "true" || false,
  },
  {
    key: "invoice",
    label: "Faktura",
    disabled: process.env.NEXT_PUBLIC_INVOICE_DISABLED === "true" || false,
  },
];

const PaymentSection = () => {
  const { register, control, setValue } = useFormContext<CheckoutFormValues>();
  const paymentMethod = useWatch({
    control,
    name: "paymentMethod",
  });
  const runners = useWatch({
    control,
    name: "runners",
  });
  const numberOfRunners = runners?.length || 0;

  return (
    <div className="mb-4">
      <Card includePadding={false}>
        <div className="p-4">
          <h2 className="text-xl mb-4">Betalmetod</h2>
        </div>
        <RadioGroup
          value={paymentMethod}
          onChange={(selectedMethod) =>
            setValue("paymentMethod", selectedMethod)
          }
        >
          {methods
            .filter((m) => !m.disabled)
            .map((method) => {
              const isDisabled =
                method.key === "invoice" && numberOfRunners < 10;
              const isCard = method.key === "card" && paymentMethod === "card";
              const isSwish =
                method.key === "swish" && paymentMethod === "swish";
              const isInvoice =
                method.key === "invoice" && paymentMethod === "invoice";

              return (
                <RadioGroup.Option
                  key={method.key}
                  value={method.key}
                  disabled={isDisabled || false}
                >
                  {({ checked }) => (
                    <div
                      id={`method-${method.key}`}
                      key={method.key}
                      onClick={() =>
                        !isDisabled && setValue("paymentMethod", method.key)
                      }
                      className={`${
                        isDisabled
                          ? "bg-gray-100 "
                          : checked
                          ? "selected bg-sky-100 "
                          : ""
                      }leading-8 border-t ${
                        isDisabled
                          ? ""
                          : "hover:bg-sky-100 hover:cursor-pointer"
                      } px-4 py-2`}
                    >
                      {checked ? (
                        <CheckedIcon className="inline text-thunderLight mr-2 w-5 h-5 align-sub" />
                      ) : (
                        <UncheckedIcon className="inline text-gray-300 mr-2 w-5 h-5 align-sub" />
                      )}
                      {method.label}
                      {method.key === "invoice" && isDisabled ? (
                        <div>
                          <span className="text-xs text-gray-600">
                            Faktura finns endast för grupper med 10+ löpare
                          </span>
                        </div>
                      ) : null}
                      {isCard && (
                        <div>
                          <span className="text-xs text-gray-600">
                            Du kommer skickas vidare till vår betalsida
                          </span>
                        </div>
                      )}
                      {isSwish && (
                        <Input
                          register={register}
                          name="phoneNumber"
                          label="Telefonnummer"
                          required
                        />
                      )}
                      {isInvoice && (
                        <div>
                          <span className="text-xs text-gray-600">
                            Vi kommer kontakta den först angivna personen för faktureringsdetaljer
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </RadioGroup.Option>
              );
            })}
        </RadioGroup>
        {methods.map((method) => {
          if (method.key === "invoice" && numberOfRunners < 10) {
            return null;
          }
        })}
      </Card>
    </div>
  );
};

export default PaymentSection;
