import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Runner } from "../interfaces/runner";
import { CheckoutFormValues } from "../pages";
import Button from "./Button";
import Card from "./Card";
import CategorySelect from "./CategorySelect";
import GroupSelect from "./GroupSelect";
import Input from "./Input";
import RemoveButton from "./RemoveButton";
import TeamSelect from "./TeamSelect";

const defaultValue: Runner = {
  firstName: "",
  lastName: "",
  email: "",
  startGroup: "",
  category: "",
  team: "",
  isContact: false,
};

const RunnerSection = () => {
  const { register, getValues, setValue } =
    useFormContext<CheckoutFormValues>();
  const { fields, append, remove } = useFieldArray<CheckoutFormValues>({
    name: "runners",
  });

  const resetPaymentMethod = () => {
    if (fields.length <= 10 && getValues("paymentMethod") === "invoice") {
      setValue("paymentMethod", "card");
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id} className="mb-4">
          <Card>
            <h2 className="text-xl mb-4">
              {field.isContact ? "Löpare" : `Löpare ${index + 1}`}
            </h2>
            {!field.isContact && (
              <div className="absolute right-4 top-4">
                <RemoveButton
                  onRemove={() => {
                    remove(index);
                    resetPaymentMethod();
                  }}
                />
              </div>
            )}

            <div className="w-full flex-wrap md:flex md:flex-wrap md:gap-4">
              <div className="mb-6 md:mb-4 md:grow md:max-w-[calc(50%-0.5rem)]">
                <Input
                  register={register}
                  name={`runners.${index}.firstName`}
                  label="Förnamn"
                  required
                />
              </div>
              <div className="mb-6 md:mb-4 md:grow md:max-w-[calc(50%-0.5rem)]">
                <Input
                  register={register}
                  name={`runners.${index}.lastName`}
                  label="Efternamn"
                  required
                />
              </div>
            </div>
            <div className="w-full flex-wrap md:flex md:flex-wrap md:gap-4">
              <div className="mb-6 md:mb-4 md:grow md:max-w-[calc(50%-0.5rem)]">
                <Input
                  register={register}
                  name={`runners.${index}.email`}
                  label="E-post"
                  required
                  pattern={
                    new RegExp(
                      "^([-a-zA-Z0-9_.]+)@([-a-zA-Z0-9_.]+).([a-zA-Z]{2,5})$"
                    )
                  }
                />
              </div>
            </div>
            <div className="w-full flex-wrap md:flex md:flex-wrap md:gap-4">
              <div className="mb-6 md:mb-4 md:grow md:max-w-[calc(50%-0.5rem)]">
                <GroupSelect
                  name={`runners.${index}.startGroup`}
                  classNamePrefix={`group-select-${index}`}
                />
              </div>
              <div className="mb-6 md:mb-4 md:grow md:max-w-[calc(50%-0.5rem)]">
                <CategorySelect
                  name={`runners.${index}.category`}
                  classNamePrefix={`category-select-${index}`}
                />
              </div>
            </div>
            <div className="w-full flex-wrap md:flex md:flex-wrap md:gap-4">
              <div className="mb-6 md:mb-4 md:grow md:max-w-[calc(50%-0.5rem)]">
                <TeamSelect
                  name={`runners.${index}.team`}
                  classNamePrefix={`team-select-${index}`}
                />
              </div>
            </div>
          </Card>
        </div>
      ))}
      <div className="mb-8">
        <Button
          id="add-runner-btn"
          type="button"
          onClick={() =>
            append({
              ...defaultValue,
              id: uuidv4(),
              team: getValues("runners")?.[fields.length - 1]?.team,
            })
          }
        >
          Lägg till löpare
        </Button>
      </div>
    </>
  );
};

export default RunnerSection;
