import { useMutation, MutationStatus } from "react-query";
import axios from "axios";

export interface VoucherResponse {
  code: string;
  type: string;
  discountValue: number;
}

interface UseVoucher {
  onValidateVoucher: (code?: string) => Promise<VoucherResponse | undefined>;
  status: MutationStatus;
}

const useVoucher = (): UseVoucher => {
  const validateMutation = useMutation(async (code: string) => {
    const { data } = await axios.post<VoucherResponse>(
      `${process.env.NEXT_PUBLIC_WEB_BASE}/api/validateVoucher`,
      { code }
    );
    return data;
  });

  const handleValidate = async (code?: string) => {
    if (code) {
      const res = await validateMutation.mutateAsync(code);
      return res;
    }
  };

  return { onValidateVoucher: handleValidate, status: validateMutation.status };
};

export default useVoucher;
