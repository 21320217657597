import React, { useState } from "react";
import {
  FaRegTrashAlt as TrashIcon,
  FaExclamationTriangle as WarningIcon,
} from "react-icons/fa";
import Button from "./Button";

interface Props {
  onRemove: () => void;
}

const RemoveButton = ({ onRemove }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <Button
        id="remove-btn"
        type="button"
        variant="danger"
        onClick={() => setIsOpen((o) => !o)}
        tabIndex={-1}
      >
        <TrashIcon className="md:mr-2 text-red-500" />
        <span className="hidden text-red-500 display-inline text-sm font-medium md:block">
          Ta bort löpare
        </span>
      </Button>
      {isOpen && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setIsOpen(false)}
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start align-center">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <WarningIcon className="text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Ta bort löpare
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Vill du verkligen ta bort denna löpare?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-end gap-4">
                <Button
                  id="close-btn"
                  variant="secondary"
                  type="button"
                  onClick={() => setIsOpen(false)}
                >
                  Stäng
                </Button>
                <Button
                  id="confirm-btn"
                  type="button"
                  variant="danger"
                  onClick={() => onRemove()}
                >
                  Ta bort
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RemoveButton;
