export default function getUnitFromDiscountType(type: string): string {
  switch (type) {
    case "Percentage":
      return "%";

    case "Amount":
      return " kr";

    default:
      throw new Error(`Unknown discountType ${type}`);
  }
}

export const getDiscountTypeFromEnum = (type: number) => {
  switch (type) {
    case 0:
      return "Percentage";

    case 1:
      return "Amount";

    default:
      throw new Error(`Unknown discountType ${type}`);
  }
}
