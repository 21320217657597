import Select from "react-select";
import { useController } from "react-hook-form";
import { selectStyles, errorStyles } from "../styles/selectStyles";
import useCategories from "../hooks/useCategories";

interface Props {
  name: string;
  classNamePrefix: string;
}

const CategorySelect = ({ name, classNamePrefix }: Props) => {
  const { categories, status } = useCategories();
  const { field, fieldState } = useController({
    name,
    rules: { required: true },
  });

  const error = fieldState.invalid && fieldState.error;

  const translateLabel = (label: string) => {
    switch (label) {
      case "Men":
        return "Män";
      case "Women":
        return "Kvinnor";

      default:
        return "";
    }
  };
  return (
    <div className="relative">
      <label
        className={`block ${
          error ? "text-red-600" : "text-gray-700"
        } text-sm font-normal mb-1`}
        htmlFor={"category-list"}
      >
        Klass*
      </label>
      <Select
        inputId="category-list"
        styles={error ? errorStyles : selectStyles}
        options={categories.map((category) => ({
          label: translateLabel(category),
          value: category,
        }))}
        value={{ value: field.value, label: translateLabel(field.value) }}
        onChange={(val) => field.onChange(val?.value)}
        name={field.name}
        isLoading={status === "loading"}
        onBlur={field.onBlur}
        classNamePrefix={classNamePrefix}
      />
      {error && error.type === "required" && (
        <span className="absolute -bottom-5 text-xs text-red-600">
          Detta fält måste fyllas i
        </span>
      )}
    </div>
  );
};

export default CategorySelect;
