import { useQuery, QueryStatus } from "react-query";
import axios from "axios";

interface UseTeams {
  teams: string[];
  status: QueryStatus;
}

export interface TeamsResponse {
  teams: string[];
}

const useTeams = (): UseTeams => {
  const { data, status } = useQuery<TeamsResponse>("teams", async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_WEB_BASE}/api/teams`
    );
    return res.data;
  });

  return { teams: data?.teams || [], status };
};

export default useTeams;
